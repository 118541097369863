<template>
  <div>
    <h1 class="title is-1">
      <span>Nyilatkozat {{ $route.params.statementID }}</span>
      <span v-if="Number($store.state.inspection.selected.status_id) === 3">
        <b-button v-if="isEditable"
                  @click="cancel"
                  type="is-secondary"
                  icon-left="times"
                  class="is-pulled-right">
          Bezár
        </b-button>
        <b-button v-if="isEditable"
                  @click="$refs.document.save()"
                  type="is-primary"
                  icon-left="save"
                  class="is-pulled-right">
          Mentés
        </b-button>
        <b-button v-else
                  @click="isEditable = true"
                  type="is-primary"
                  icon-left="edit"
                  class="is-pulled-right">
          Szerkesztés
        </b-button>
      </span>
    </h1>
    <Document :document-id="documentID"
              :is-editable="isEditable"
              :key="documentKeyForRerender"
              :use-auto-save="false"
              @saved="saved"
              ref="document"/>
  </div>
</template>

<script>

import Document from '@/components/Document'

export default {
  name: 'InspectionStatement',
  components: { Document },
  data () {
    return {
      documentID: this.$route.params.statementID,
      isEditable: false,
      documentKeyForRerender: 0,
    }
  },
  methods: {
    saved () {
      this.isEditable = false
    },
    cancel () {
      this.isEditable = false
      // if key changed rerender document component
      this.documentKeyForRerender++
    }
  },
  watch: {
    '$route.params.statementID': {
      handler: async function () {
        this.isEditable = false
        this.documentID = this.$route.params.statementID
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style scoped>

</style>
